<template>
    <div class="box">
		<SeasonInput v-model="seasonSelected" @change="loadSeasonMareStallions" />

        <CustomTable
            id_table="jumenterie"
            ref="jumenterie"
            :items="season_mare_stallions"
			:rawColumns="rawColumnNames"
            :busy.sync="table_busy"
            primaryKey="seasonmarestallion_id"
            :hide_if_empty="true"
			:hrefsRoutes="config_table_hrefs"
            :externSlotColumns="['mare.horse.lieustaionnement_whatsapp', 'mare.horse.horse_tiers', 'mare.horse.horse_tiers_mail']"
            :groupByCustom="customGroupBy"
        >
			<template v-slot:[`custom-slot-cell(mare.horse.lieustaionnement_whatsapp)`]="{ data }">
				<a v-if="data.mare.horse && data.mare.horse.lieustationnement_phone_num" :href="`https://wa.me/${data.mare.horse.lieustationnement_phone_num}`" target="_blank">
					<font-awesome-icon :icon="['fab', 'whatsapp']" />
				</a>
			</template>
			<template v-slot:[`custom-slot-cell(mare.horse.horse_tiers)`]="{ data }">
				<template v-if="data.mare">
					<router-link 
						v-for="tiers in data.mare.horse.horse_tiers" 
						:to="{ name: 'tiersFiche', params: { tiers_id: tiers.tiers_id } }"
						:key="data.seasonmarestallion_id+'_'+tiers.tiers_id">
						{{ tiers.tiers_rs }}<br>
					</router-link>
				</template>
			</template>
			<template v-slot:[`custom-slot-cell(mare.horse.horse_tiers_mail)`]="{ data }">
				<template v-if="data.mare">
					<span v-for="tiers in data.mare.horse.horse_tiers" :key="data.seasonmarestallion_id+'_'+tiers.tiers_id">
						<a :href="'mailto:'+tiers.tiers_mail">{{ tiers.tiers_mail }}</a><br>
					</span>
				</template>
			</template>
    	</CustomTable>

		<b-modal ref="modal_change_dps" size="xl" hide-footer>
			<template v-slot:modal-title>
				{{ $t("gynecologie.change_dps") }}
			</template>
			
			<input type="text" class="form-control" :placeholder="$t('gynecologie.new_num_dps')"
				v-model="season_mare_stallion.seasonmarestallion_num_dps"
			>
			<div class="text-center">
				<button class="btn btn-primary mt-4" type="button" @click.prevent="saveNewDps">{{ $t('global.valider') }}</button>
			</div>
		</b-modal>

		<b-modal ref="modal_confim_change_dps" hide-footer>
			<template v-slot:modal-title>
				{{ $t("gynecologie.change_dps") }}
			</template>

			<h5 class="text-center">{{ $t('gynecologie.confirmation_change_dps') }}</h5>

			<div class="row justify-content-around mt-4" >
				<button class="btn btn-primary" type="button" @click.prevent="saveChange(false)">{{ $t('global.non') }}</button>
				<button class="btn btn-primary" type="button" @click.prevent="saveChange(true)">{{ $t('global.oui') }}</button>
			</div>
		</b-modal>

		<ModalPrintInfosHorse ref="modal_print_infos_horse"/>
		<ModalAddCross ref="modal_add_cross" @submit="changeSeason"/>
		<ModalActDGLot ref="modal_act_dg_lot" @submit="afterDG"/>
		<ModalSendDG ref="modal_send_dg" @submit="stopSpin"/>
		<ModalAskDPS ref="modal_ask_dps" :seasonmares="true" @submit="dpsAsked"/>
		<ModalActiveSeason ref="modal_active_season" />

    </div>
</template>

<script type="text/javascript">
	import { EventBus } from 'EventBus';
    import GynecologieMixin from "@/mixins/Gynecologie.js"
    import ActeType from "@/mixins/ActeType.js"
	import Navigation from '@/mixins/Navigation.js'
    import Common from '@/assets/js/common'
    import _groupBy from 'lodash/groupBy'

    export default {
        name: "Jumenterie",
        mixins: [GynecologieMixin, ActeType, Navigation],
        props: ['horse'],
        data () {
            return {
                table_busy: true,
                seasonSelected: null,
                seasons: [],
				season_mare_stallions: [],
				season_mare_stallion: {},
				config_table_hrefs: {
                    'mare.horse.horse_nom': {
						routeUniqueName: 'JumentFiche',
                        routeName: 'HorseFicheInfo',
                        params: {
                            horse_id: 'mare.horse.horse_id'
                        }
					},
					'horse.horse_nom': {
						routeUniqueName: 'EtalonFiche',
                        routeName: 'HorseFicheInfo',
                        params: {
                            horse_id: 'horse.horse_id'
                        }
                    },
					'porteuse.horse.horse_nom': {
						routeUniqueName: 'PorteuseFiche',
						routeName: 'HorseFicheInfo',
						params: {
							horse_id: 'porteuse.horse.horse_id'
						}
					},
					'stallion_previous_season.horse_nom': {
						routeUniqueName: 'EtalonFichePrevious',
                        routeName: 'HorseFicheInfo',
                        params: {
                            horse_id: 'stallion_previous_season.horse_id'
                        }
                    },
					'mare.horse.lieustationnement': {
						routeUniqueName: 'tiersFiche',
                        routeName: 'tiersFiche',
                        params: {
                            tiers_id: 'mare.horse.lieustationnement_id'
                        }
                    },
                },
				events_tab: {
					'TableAction::goToPrintInfosHorseJumenterie': (params) => {
						this.openModalPrintInfosHorse(params)
					},
					'TableAction::goToPdfSuivi': (params) => {
						this.load_pdf_suivi(params)
					},
					'TableAction::goToAddCross': () => {
						this.openModalAddCross()
					},
					'TableAction::goToActDgLot': (params) => {
						this.openModalActDGLot(params)
					},
					'TableAction::goToArchiveCouple': (params) => {
						this.goToArchiveCouple(params)
					},
					'TableAction::goToSendDg': (params) => {
						this.openModalSendDG(params)
					},
					'TableAction::goToAddActeDonneuse': (params) => {
						this.loadActForm('donneuse', params)
					},
					'TableAction::goToAddActePorteuse': (params) => {
						this.loadActForm('porteuse', params)
					},
					'TableAction::goToChangeDpsNum': (params) => {
						this.changeDpsNum(params)
					},
					'TableAction::goToAskDPS': (params) => {
						this.askDPS(params)
					},
					'TableAction::goToActiveOtherSeason': this.openActiveSeason
				},
				rawColumnNames: ['seasonmarestallion_state'],
				customGroupBy: {'mare.horse.horse_tiers': this.horseTiersFormat, 'mare.horse.horse_tiers_mail': this.horseTiersMailFormat},
				dps: null,
            }
        },
        methods: {
            async changeSeason() {
                this.table_busy = true
                this.season_mare_stallions = await this.etatJumenterie(this.seasonSelected.id)
                this.table_busy = false
				this.unselectAll()
            },
			load_pdf_suivi(params) {
				params.forEach(croisement => {
					if(!croisement.horse.horse_id) {
						this.failureToast("toast.no_current_stallion")
					}
					this.generatePdfSuiviGyneco(croisement.mare.horse.horse_id, this.seasonSelected.id, croisement.horse.horse_id)
				});
			},
			async loadSeasonMareStallions() {
				if(!this.seasonSelected) {
					return
				}

				this.table_busy = true

				this.season_mare_stallions = await this.etatJumenterie(this.seasonSelected.id)
				for (let i = 0; i < this.season_mare_stallions.length; i++) {
					let color = ''
					if(this.season_mare_stallions[i].seasonmarestallion_state.indexOf('DG+') != -1 || this.season_mare_stallions[i].seasonmarestallion_state.indexOf('PD+') != -1) {
						color = 'green'
					}
					else if(this.season_mare_stallions[i].seasonmarestallion_state.indexOf('DG-') != -1 || this.season_mare_stallions[i].seasonmarestallion_state.indexOf('PD-') != -1) {
						color = 'orange'
					}
					else if(this.season_mare_stallions[i].seasonmarestallion_state.indexOf('Avorté') != -1 || this.season_mare_stallions[i].seasonmarestallion_state.indexOf('Aborted') != -1) {
						color = 'orange'
					}
                    this.season_mare_stallions[i].customClasses={
                        seasonmarestallion_state: color
                    }
                }

				this.table_busy = false
            },
			openModalPrintInfosHorse(params) {
				let mare_ids = []
				params.forEach(croisement => {
					mare_ids.push(croisement.mare.horse.horse_id)
				});
				this.$refs.modal_print_infos_horse.openModal(mare_ids)
			},
			openModalAddCross() {
				if(this.seasonSelected.id) {
					this.$refs.modal_add_cross.openModal(this.seasonSelected.id)
				}
				else {
					this.failureToast("error.LEP")
				}
			},
			openModalActDGLot(params) {
				this.$refs.modal_act_dg_lot.openModal(params)
			},
            async goToArchiveCouple(sms) {
                await this.archiveOrUnarchiveCouple(sms)
                this.$refs.jumenterie.unselectAll()
                this.changeSeason()
            },
			afterDG() {
				this.unselectAll()
				this.loadSeasonMareStallions()
			},
			unselectAll() {
        		this.$refs.jumenterie.unselectAll()
        	},
			openModalSendDG(params) {
				this.$refs.modal_send_dg.openModal(params)
			},
			stopSpin() {
				EventBus.$emit('TableAction::stopSpin')
			},
			horseTiersFormat(el) {
				let res = el
				if(Array.isArray(el)) {
					res = el.map(tier => tier.tiers_rs).join(', ')
				}
				return res == '' ? 'N/A' : res
			},
			horseTiersMailFormat(el) {
				let res = el
				if(Array.isArray(el)) {
					res = el.map(tier => tier.tiers_mail).join(', ')
				}
				return res == '' ? 'N/A' : res
			},
			loadActForm(type, couples) {
				let horse_ids = []
				let params = {from: this.$route.name}

				couples.forEach(couple => {
					if(type == 'donneuse' && couple.mare && couple.mare.horse && couple.mare.horse.horse_id) {
						horse_ids.push(couple.mare.horse.horse_id)
					}
					else if(type == 'porteuse' && couple.porteuse && couple.porteuse.horse && couple.porteuse.horse.horse_id) {
						horse_ids.push(couple.porteuse.horse.horse_id)
					}
				})

				if(horse_ids.length > 0) {
					params.horse_id = horse_ids.join(', ')
					this.$router.push({ name: 'acteAjout', params: params })
				}
				else {
					this.failureToast('toast.erreur_ajout_acte')
				}
			},
			async changeDpsNum(params) {
				const teledeclaration = await this.getHorseDpsStatus()
				if(!teledeclaration) {
					this.season_mare_stallion = params[0]
					this.$refs.modal_change_dps.show()
				}
				else {
					this.failureToast("toast.teledeclaration")
				}
			},

			async saveNewDps() {
				this.$refs.modal_change_dps.hide()
				this.$refs.modal_confim_change_dps.show()
			},

			async saveChange(update) {
				this.$refs.modal_confim_change_dps.hide()
				if (update){
					const res = await this.editSeasonMareStallion(this.season_mare_stallion.seasonmarestallion_id, this.season_mare_stallion)
					if (res){
						this.$refs.jumenterie.refreshTable()
						this.infoToast('toast.info_modif_succes')
						return
					}
					this.failureToast(this.getTrad("horse.info_modif_failed"), true)
				}
            },

			dpsAsked(){
				this.unselectAll()
				this.changeSeason()
			},

            async askDPS(sms) {
				const items = sms.map( couple => {
					return {
						mare_id: couple.mare.horse.horse_id,
						seasonmare_id: couple.seasonmarestallion_seasonmare,
					}
				})
                this.$refs['modal_ask_dps'].openModal(this.seasonSelected.id, items)
            },

            openActiveSeason(sms) {
            	const mares_ids = [... new Set(sms.map(s => s.mare.horse.horse_id))]
                this.$refs['modal_active_season'].openModal(mares_ids)
            }
        },
        components: {
            CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
            SeasonInput : () => import('@/components/Inputs/SeasonInput'),
			ModalPrintInfosHorse : () => import('@/components/Horse/ModalPrintInfosHorse'),
			ModalAddCross : () => import('@/components/Gynecologie/ModalAddCross'),
			ModalActDGLot : () => import('@/components/Gynecologie/ModalActDGLot'),
			ModalSendDG : () => import('@/components/Gynecologie/ModalSendDG'),
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			ModalAskDPS: () => import('@/components/Modals/ModalAskDPS'),
			ModalActiveSeason: () => import('@/components/Modals/ModalActiveSeason')
        }
    }
</script>
